










































import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import AuthContainer from '@/components/AuthContainer.vue';
import { ErrorAPI, MerchantAPI } from '@/api';

@Component({
  components: {
    AuthContainer
  },
})
export default class ForgetPassword extends Vue {
  private email: string = '';
  private password: string = '';
  private passwordConfirmation: string = '';
  private errors = new ErrorAPI();
  private isLoading: boolean = false;

  private reset() {
    this.isLoading = true;
    this.errors.clear();

    if (!this.validate()) {
      return false;
    }

    MerchantAPI
      .passwordReset(
        this.$route.params.token,
        this.email,
        this.password,
        this.passwordConfirmation,
      )
      .then(() => this.$router.push('/dashboard'))
      .catch(ErrorAPI.parse(this.errors))
      .finally(() => this.isLoading = false);
  }

  private validate() {
    const form = this.$refs['vuejs-form-data'] as HTMLFormElement;
    if (form.checkValidity && !form.checkValidity()) {
      const elements = [...form.elements] as HTMLElement[];
      elements.forEach((e) => e.focus());
      // form.reportValidity();
      return false;
    }

    return true;
  }
}
